import Grid from "@mui/material/Unstable_Grid2";
import "../styles.css";
import {
  SectionHeading,
  Subheading,
  InfoWrapper,
  InformationMessage,
} from "../styles";
import { COLORS } from "../../../constants";

const About = () => {
  return (
    <Grid container sx={{ background: COLORS.BACKGROUND_NEUTRAL }}>
      <Grid xs={1} md={2}></Grid>
      <Grid xs={10} md={8}>
        <InfoWrapper>
          <SectionHeading>About</SectionHeading>
          <InformationMessage>
            Running two sessions. You can sign up for one or both:
          </InformationMessage>
          <Subheading>SESSION 1</Subheading>
          <InformationMessage>
            September 16th - December 5th
            <br />
            Every Monday and Thursday
            <br />
            Mondays 7:00-8:30pm at Lincoln Middle School
            <br />
            Thursdays 7:00-8:30pm at Lincoln Middle School
          </InformationMessage>
          <Subheading>SESSION 2</Subheading>
          <InformationMessage>
            September 18th - December 1st
            <br />
            Every Wednesday and Sunday
            <br />
            Wednesdays 7:00-8:30pm at Lincoln Middle School
            <br />
            Sundays 3:00-5:00pm at Sturgeon Heights Community Center
          </InformationMessage>   
          <Subheading>WHO</Subheading>
          <InformationMessage>
            Girls ages 15 and under. No prior volleyball experience needed. Will
            be covering basics for beginners and offer more practice for
            experienced players.
          </InformationMessage>
          <Subheading>COST</Subheading>
          <InformationMessage>Cost $400/session</InformationMessage>
          <Subheading>HOW</Subheading>
          <InformationMessage>
            Payment can be made by e-transfer, cash or cheque by end of September
          </InformationMessage>
        </InfoWrapper>
      </Grid>
    </Grid>
  );
};

export default About;
